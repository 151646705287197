.BrainhubCarousel__dot,
.BrainhubCarousel__dot--selected {
    padding: 0 !important;
    width: 15px;
    height: 15px;
    background-color: black;
}

.BrainhubCarousel__dot {
    opacity: 0.25 !important;
    border-radius: 50%;
}

.planview {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: space-around;
    flex-direction: column;
    width: 34vw;
}

.planview h4 {
    width: 34vw;
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (max-width: 850px) {
    .planview h4 {
        margin-left: 30vw;
    }
}
.planview div {
    margin-top: 3vw;
}
@media screen and (max-width: 850px) {
    .planview div {
        margin-top: 5vw;
    }
    .planview {
        width: 100%;
        height: 100%;
    }
}

.BrainhubCarouselItem {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: space-around;
}

.BrainhubCarousel__dot:hover {
    background-color: black;
    color: black;
}

@media screen and (max-width: 850px) {
    .BrainhubCarousel__dots {
        padding-top: 2vw !important;
    }
    .BrainhubCarousel__dot {
        padding: 1vw !important;
        margin-right: 3vw;
    }
}
