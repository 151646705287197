.collections {
    display: flex;
    flex-direction: column;
    background-color: black;
    /*height: 95vh;*/
    padding: 2.5vw;
    gap: 2.5vw;
}

.collectionsH {
    display: flex;
    flex-direction: row;
    background-color: black;
    height: 95vh;
    padding: 2.5vw;
    gap: 2.5vw;
}
.cat {
    width: 80vw;
    height: 40vh;
    overflow: hidden;
    position: relative;
    text-align: center;
    color: white;
    cursor: pointer;
}

.tall {
    height: 52vh;
}

.catH {
    width: 45vw;
    height: 95vh;
    overflow: hidden;
    position: relative;
    text-align: center;
    color: white;
    cursor: pointer;
}

.cat img {
    width: 100%;
    object-fit: contain;
    transform: translateY(-30%);
}
.catH img {
    height: 100%;
    object-fit: contain;
    transform: translateY(-30%);
}

.cat label {
    position: absolute;
    cursor: pointer;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: var(--font-large);
}

.furnature img {
    transform: scale(1.5);
}

@media screen and (max-width: 850px) {
    .collections {
        width: 100vw;
        height: 80vh;
        justify-content: center;
    }
    .cat {
        width: 95vw;
        height: 40vh;
    }
}
