.admin .header {
    display: flex;
    justify-content: space-between;
}

.admin .two-column {
    display: flex;
    justify-content: space-between;
}
.admin .chair3d {
    height: 50vw;
}
.admin .first-column,
.admin .second-column {
    padding: 2vw;
}
.admin .first-column {
    width: 35vw;
}
.menu a {
    color: black;
    font-size: var(--font-mid);
    padding-right: 2vw;
}
.print-area {
    width: 11in !important;
    page-break-after: always;
}

.print {
    font-size: 12pt;
}
.print-break-after {
    page-break-after: always;
}

.print .admin .purchase-images {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.print .admin .purchase-images {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    gap: 5px;
}

.print .admin .purchase-images .left {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.print .admin .two-column {
    justify-content: flex-start;
}
.print .menu,
.print .action-buttons,
.print .logout-button {
    display: none;
}

.print .chairs2d div {
    padding-bottom: 30px;
    padding-left: 30px;
}

.print .first-column {
    width: 5in;
}

.print .chairs2d {
    margin-top: 100px;
}
.print .control {
    margin-bottom: 0;
}

.print .chair3d {
    width: inherit;
}
.print h1.title {
    content: none;
    display: none;
}
.print .logo {
    width: 10vw;
    padding-left: 2vw;
    padding-bottom: 0vw;
}
.print .second-column {
    width: 50vw;
    padding-top: 0px;
    padding-left: 50px;
}

.print h4 {
    font-size: 16pt;
    margin-bottom: 2px;
}
