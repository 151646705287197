@font-face {
    font-family: "pitch-regular";
    src: local("pitch-regular"),
        url(../fonts/TestPitchSans-Medium.otf) format("opentype");
}

@font-face {
    font-family: "pilat-heavy";
    src: local("pilat-heavyr"),
        url(../fonts/PilatTest-Heavy.otf) format("opentype");
}

@font-face {
    font-family: "pitch-bold";
    src: local("pitch-bold"),
        url(../fonts/TestPitchSans-Bold.otf) format("opentype");
}

@font-face {
    font-family: "pitch-light";
    src: local("pitch-light"),
        url(../fonts/TestPitchSans-Light.otf) format("opentype");
}

:root {
    --font-vsmall: 0.8vw;
    --font-small: 1.2vw;
    --font-mid: 1.6vw;
    --font-large: 3vw;
    --font-vlarge: 7.5vw;
    --color-green: #73b75b;
    --mobile: 0;
}

@media screen and (max-width: 850px) {
    :root {
        --font-vsmall: calc(1vw * 2.5);
        --font-small: calc(1.2vw * 2.5);
        --font-mid: calc(1.6vw * 2.5);
        --font-large: calc(3vw * 2.5);
        --font-vlarge: calc(7.5vw * 2.5);
        --mobile: 1;
    }
}
body {
    height: 100%;
    min-height: 110vw;
    width: 100%;
    font-family: pitch-regular;
    font-size: var(--font-small);
}

#PopupSignupForm_0 .strictContent h2 {
    font-family: pitch-light !important;
}
button {
    background-color: white;
    border: 1px solid rgb(59, 26, 26);
    color: black;
    padding: 0.5vw 2vw;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: var(--font-vsmall);
    margin: 0.25vw 0.45vw;
    text-transform: uppercase;
    font-family: "pitch-bold";
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    transition: background-color 0.75s, color 0.75s, width 0.75s;
}

@media screen and (max-width: 850px) {
    button {
        padding: 1vw 4vw;
    }
}

input[type="text"] {
    background-color: white;
    border: 1px solid black;
    color: black;
    padding: 0.5vw 2vw;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: var(--font-vsmall);
    text-transform: uppercase;
    font-family: "pitch-bold";
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
}
.input-padded {
    margin: 0.25vw 0.45vw;
}

button:hover {
    background-color: black;
    color: white;
    transition: background-color 0.75s, color 0.75s, width 0.75s;
}

button.selected {
    background-color: black;
    color: white;
}

.App {
    height: 100vh;
}

.sub-title {
    text-transform: uppercase;
    font-family: "pitch-bold";
    font-size: var(--font-small);
}

.sub-sub-title {
    text-transform: uppercase;
    font-family: "pitch-bold";
    font-size: var(--font-vsmall);
}

.small {
    font-family: "pitch-regular";
    font-size: var(--font-vsmall);
}

.one-word-per-line {
    width: min-intrinsic;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    display: table-caption;
    display: -ms-grid;
    -ms-grid-columns: min-content;
}

.back-button {
    position: fixed;
    bottom: 2vw;
    left: 5vw;
    cursor: pointer;
}

.back-button img {
    height: 3vw;
    width: 3vw;
    filter: drop-shadow(00rem 1rem 1rem black);
}

h1 {
    font-family: pitch-bold;
    font-weight: bold;
    font-size: var(--font-large);
    line-height: 180.99%;
}

p {
    font-size: var(--font-small);
    line-height: 180%;
}

.forward-button {
    position: fixed;
    bottom: 2vw;
    right: 5vw;
    cursor: pointer;
    filter: drop-shadow(00rem 1rem 1rem black);
    height: 3vw;
    border: 0.02vw black solid;
    text-decoration: none;
    color: black;
    font-size: var(--font-small);
    background-color: white;
    margin: auto;
    line-height: 3vw;
    border-radius: 1vw;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
}

.clickable {
    cursor: pointer;
}

table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
}

thead tr {
    background-color: white;
    color: #272727;
    text-align: left;
    font-weight: bold;
    border-bottom: 2px solid #272727;
}

th,
td {
    padding: 1vw 0.6vw;
}
tbody tr {
    border-bottom: 1px solid #c7c7c7;
}

tbody tr:hover {
    background-color: #c7c7c7;
}

.gcart-badge {
    position: fixed;
    padding-top: 0.2vw;
    bottom: 1vw;
    right: 1vw;
    border: 1px black dashed;
    background-color: white;
    z-index: 9999999;
    cursor: pointer;
}
.gcart-badge svg {
    fill: white;
}
