.controls {
    width: 20vw;
    border: 1px solid black;
    align-items: center;
}

.controls.with-size {
    padding-left: 22vw;
    width: 15vw;
    justify-content: flex-start;
}

.control {
    display: block;
    text-align: left;
    margin-bottom: 3vw;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
}
@media screen and (max-width: 800px) {
    .control {
        display: block;
        text-align: left;
        margin-bottom: 6vw;
        padding-left: 1vw;
        padding-right: 1vw;
        min-height: 3vw;
    }
}
.control.double-height {
    height: 14vw;
}
.control label {
    font-family: pitch-bold;
    font-size: var(--font-small);
}
.control .value {
    text-align: right;
    margin: auto;
}

.control sup,
.contorl sub {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
}
.contorl sub {
    top: 0.4em;
}

.icon .konvajs-content {
    text-align: center;
    margin: auto;
}

.icon .label {
    margin-top: 1vw;
    font-family: pitch-bold;
    width: 100%;
    text-align: center;
    font-size: var(--font-vsmall);
}
.left-control {
    width: 15vw;
    margin-top: 1vw;
    margin-right: 1vw;
}

.controls .number.input input {
    font-size: var(--font-small);
    text-align: center;
    width: 2vw;
    height: 2.6vw;
}

.controls .number-mod {
    display: inline-block;
    border: 1px solid black;
    border-radius: 50%;
    font-size: var(--font-small);
    width: 1.3vw;
    height: 1.3vw;
    line-height: 1.3vw;
    text-align: center;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    user-select: none;
}

.controls .section-descr .descr {
    font-size: var(--font-small);
    font-family: pitch-bold;
    text-transform: lowercase;
}

.controls .section-descr .sub-descr {
    font-size: var(--font-vsmall);
    font-family: pitch-bold;
    text-transform: lowercase;
}

.controls .section-value {
    margin-right: none;
    text-align: right;
}

.controls .title {
    text-align: left;
    align-self: flex-start;
    font-size: var(--font-small);
    font-family: pitch-bold;
    text-transform: lowercase;
}

.controls .color {
    display: inline-block;
    width: 5vw;
    height: 5vw;
}
.controls .color img {
    border-radius: 50%;
    width: 80%;
    height: 80%;
}

#selected-color {
    filter: drop-shadow(00rem 1rem 1rem black);
    width: 3vw;
    height: 3vw;
    border-radius: 50%;
}

.color-selection {
    position: absolute;
    right: 0;
    background-color: white;
    z-index: 1000;
}
