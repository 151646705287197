hr {
    border: 1px solid rgba(0, 0, 0, 0.1);
}

h2 {
    margin-top: 3em;
    font-size: var(--font-mid);
}

h4 {
    font-size: var(--font-small);
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-left: 0.5vw;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-top: 3em;
}

h5 {
    font-size: var(--font-small);
    padding-left: 0.5vw;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-top: 3em;
}

.link {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}
