.custom-block {
    height: 2.2vw;
    display: flex;
    width: 98%;
    justify-content: flex-start;
    padding-bottom: 1vw;
    padding-left: 1%;
}

.light-item {
    display: flex;
    justify-content: center;
}
.custom-block .label {
    display: block;
    padding-top: 0.4vw;
    padding-left: 1%;
}

img.trash {
    height: 2vw;
    cursor: pointer;
    margin-left: 20px;
}

.block-color {
    width: 120px;
}

.custom-add {
    width: 98%;
    padding-left: 1%;
    background-color: black;
    color: white;
    text-align: center;
    height: 2vw;
    margin: auto;
    line-height: 2vw;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.75s, color 0.75s;
}

.custom-add:hover {
    background-color: white;
    border: 1px solid black;
    color: black;
    transition: background-color 0.75s, color 0.75s;
}

.react-numeric-input input {
    height: 2.2vw;
    width: 4vw;
}

.react-numeric-input b {
    position: absolute;
    right: 2px;
    width: 2.26ex;
    border-color: black !important;
    border-style: solid;
    text-align: center;
    cursor: default;
    transition: all 0.1s;
    background-color: white !important;
    box-shadow: none !important;

    border-top-left-radius: none !important;
    border-top-right-radius: none !important;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-width: 1px 1px 1px 0.5px !important;
    background-position: initial initial;
    background-repeat: initial initial;
    padding-bottom: 1px !important;
}

.react-numeric-input input {
    pointer-events: none;
}

@media screen and (max-width: 850px) {
    .custom-block {
        height: 7vw;
        display: flex;
        width: 98%;
        justify-content: space-between;
        padding-bottom: 1vw;
        padding-left: 1%;
    }
    img.trash {
        height: 7vw;
    }
    .react-numeric-input input {
        height: 7vw;
        width: 20vw;
    }
    .custom-block .label {
        padding-top: 2vw;
    }
}
