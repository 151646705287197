.main-view {
    display: grid;
    grid-template-columns: 10% 35% 5% 35% 10%;
}
@media screen and (max-width: 850px) {
    .main-view {
        display: grid;
        grid-template-columns: 5% 90% 5% 35% 10%;
    }
}

.main-left {
    grid-column-start: 2;
}
@media screen and (max-width: 850px) {
    .main-left {
        margin-top: 70vw;
    }
}

.main-right {
    grid-column-start: 4;
}

.main-right {
    position: fixed;
    top: 0vw;
    left: 50vw;
    width: 35vw;
}

@media screen and (max-width: 850px) {
    .main-right {
        position: fixed;
        top: 0vw;
        left: 0;
        padding-left: 0vw;
        width: 100vw;
        background-color: white;
    }
}

.toggle-platform {
    display: flex;
    width: 100%;
    margin-bottom: 2vw;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.toggle-platform .toggle {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 48%;
    border: 1px solid #c6c6c6;
    height: 5vw;
    margin: auto;
    cursor: pointer;
    transition: background-color 0.75s, color 0.75s;
}

@media screen and (max-width: 850px) {
    .toggle-platform .toggle {
        height: 14vw;
    }
}

.toggle-platform .toggle.blank {
    border: none;
    padding: 1px;
}

.toggle-platform .toggle.compact {
    width: 31%;
}
.toggle-platform .toggle.alone {
    margin-top: 1vw;
}

.toggle-platform .toggle.quarter {
    width: 23%;
}

.toggle-platform .toggle.tall {
    height: 8vw;
}

@media screen and (max-width: 850px) {
    .toggle-platform .toggle.tall {
        height: 22vw;
    }
}

.toggle-platform .toggle.tall .image {
    margin: auto;
}

.toggle-platform .toggle.chair {
    width: 31%;
    height: 12vw;
    border: 0;
    margin-left: 0.3vw;
    margin: auto;
    text-align: center;
    border: 1px solid white;
}
.toggle-platform .toggle.chair .image {
    width: 100%;
    text-align: center;
}

@media screen and (max-width: 850px) {
    .toggle-platform .toggle.chair {
        height: 30vw;
    }
}
.toggle-platform .toggle.chair .konvajs-content {
    margin: auto;
    pointer-events: none;
}

.toggle-platform .toggle.chair.selected {
    background-color: white;
    border: 1px solid black;
    padding: 0;
}

.toggle-platform .toggle.selected {
    border: none;
    padding: 1px;
    background-color: black;
    color: white;
    transition: background-color 0.75s, color 0.75s;
}
.toggle-platform .toggle .image {
    width: 50%;
    text-align: center;
}
.toggle-platform .toggle .image img {
    width: 50%;
    margin: auto;
}
.toggle-platform .toggle .label {
    width: 50%;
    margin: auto;
    text-align: center;
}

.toggle-platform .toggle.rounded {
    border: 0px;
    padding: 0.25vw;
    height: 4.5vw;
    min-width: 4.5vw;
    max-width: 4.5vw;
    border-color: white;
    transition: border-color 0.75s, color 0.75s;
    margin: 0;
    flex-wrap: wrap;
}

.toggle-platform .toggle.rounded.halfheight {
    height: 2.25vw;
}

@media screen and (max-width: 850px) {
    .toggle-platform .toggle.rounded.halfheight {
        height: 7vw;
    }

    .toggle-platform .toggle.rounded {
        height: 14vw;
        width: 14vw;
        max-width: 14vw;
    }
}
.toggle-platform .toggle.rounded.selected {
    border: 0.25vw black solid;
    background-color: white;
    border-color: black;
    /*border-radius: 50%;*/
    padding: 0;
    transition: border-color 0.75s, color 0.75s;
}

.simple-color-selector .label {
    min-width: 4.5vw;
    max-width: 4.5vw;
    margin: auto;
    text-align: center;
}
@media screen and (max-width: 850px) {
    .simple-color-selector .label {
        max-width: 14vw;
    }
}

.toggle-platform .toggle .inside-color {
    width: 90%;
    height: 90%;
    /*border-radius: 50%;*/
    margin: auto;
}
.color-model .toggle-platform .toggle img {
    width: 100%;
    height: 100%;
    /*border-radius: 50%;*/
    margin: auto;
}

.platform-colors .toggle-platform .toggle .inside-color img:hover {
    position: relative;
    top: -25%;
    left: -25%;
    width: 150%;
    height: 150%;
    z-index: 999;
}
.color-model .inside-color:hover img {
    position: static !important;
    width: 90% !important;
    height: 90% !important;
    margin: auto;
}
.toggle-platform .toggle.selected .image img {
    filter: invert(100%);
}

.toggle-platform .toggle .inside-color img {
    width: 100%;
    height: 100%;
    /*border-radius: 50%;*/
    margin: auto;
}

.inches {
    display: block;
    height: 2vw;
}

.color-model {
    position: absolute;
    left: 5vw;
    margin-top: -3vw;
    width: 90vw;
}
.color-model .color-selection {
    box-shadow: 0.8vw 0.8vw black;
    width: 90vw;
}

.color-model .toggle-platform .toggle.rounded {
    height: 12vw !important;
    width: 12vw !important;
    max-width: 12vw;
}

@media screen and (max-width: 850px) {
    .color-model .toggle-platform .toggle.rounded {
        height: 29.5vw !important;
        width: 29.5vw !important;
        max-width: 30vw;
    }
    .color-model {
        position: absolute;
        margin-top: -30vh;
    }
}
.badge {
    position: relative;
    font-size: 2vw;
    margin-top: -120%;
    margin-left: 3vw;
    border-radius: 50%;
    background-color: black;
    height: 1.5vw;
    width: 1.5vw;
    line-height: 1.25vw;
    text-align: center;
    color: white;
}

.halfheight .badge {
    margin-top: -2.25vw;
}

@media screen and (max-width: 850px) {
    .badge {
        position: relative;
        font-size: 4vw;
        margin-top: -14vw;
        margin-left: 9vw;
        border-radius: 50%;
        background-color: black;
        height: 5vw;
        width: 5vw;
        line-height: 4vw;
        text-align: center;
        color: white;
        font-size: var(--font-large);
    }

    .halfheight .badge {
        margin-top: -6.25vw;
    }
}

.model-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url(../../images/background.png);
    opacity: 0.9;
    z-index: 500;
}

.model-body {
    position: fixed;
    top: 5vh;
    left: 30vw;
    right: 30vw;
    max-height: 80vh;
    background-color: white;
    padding: 1vw;
    opacity: 1;
    border: 1px solid black;
    box-shadow: 0.8vw 0.8vw black;
    z-index: 1000;
    overflow: scroll;
}

@media screen and (max-width: 850px) {
    .model-body {
        top: 12vh;
        max-height: 70vh;
        z-index: 1000;
    }
}

.model-text {
    width: 100%;
    text-align: center;
}
.model-buttons {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    background-color: white;
}
.model-button-blank {
    flex-grow: 4;
}

.model-button {
    margin-top: 2vw;
    width: 10vw;
}
.model-button-warning {
    background-color: black;
    color: white;
}
.model-button-warning:hover {
    background-color: white;
    color: black;
}

.model-image {
    height: 7vw;
}

.model.full-screen {
    width: 100vw;
    height: 100vh;
}

.model.full-screen .model-body {
    width: 80vw;
    top: 5vh;
    left: calc(10vw);
    right: 0;
}

.chair3d {
    height: 35vw;
    width: 35vw;
    margin-top: -12vw !important;
    margin-bottom: -10vw !important;
}

@media screen and (max-width: 850px) {
    .chair3d {
        height: 65vw;
        width: 65vw;
    }
}

.pricing-view {
    width: 35vw;
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding-bottom: 4vw;
    margin-bottom: -4.5vw;
}

.pricing-view .first-child {
    width: 100%;
    text-align: left;
}
.pricing-view-container {
    position: fixed;
    bottom: 1vw;
    left: 50vw;
}

.info {
    width: 1vw;
}

.item-summary-model {
    margin: 1vw;
    text-align: left;
    display: flex;
    width: 100%;
    justify-content: center;
}

@media screen and (max-width: 850px) {
    .info {
        width: 2vw;
    }

    .item-summary-model {
        flex-direction: column;
    }

    .pricing-view-container {
        left: 1vw;
        width: 100vw;
        height: 8vw;
    }
    .pricing-view {
        width: 90vw;
        height: 8vw;
        padding-left: 5vw;
    }

    .model-body {
        left: 5vw;
        width: 87vw;
    }
    .model-button {
        margin-top: 2vw;
        width: 25vw;
    }
}
.form-element {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0.5vw !important;
}
.form-element b {
    width: 50%;
    text-align: right;
}

.terms-check {
    width: 5% !important;
}

.form-element input {
    text-align: left !important;
    padding: 5px !important;
    width: 50%;
}
.noborder {
    border: none !important;
    border-color: transparent;
}
