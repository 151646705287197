.flex {
    display: flex;
    width: 100%;
    justify-content: center;
}

.spread {
    justify-content: space-between !important;
}

.flex-v {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    margin: auto;
}

.wrap {
    flex-wrap: wrap;
}

.flex-h {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: auto;
    flex-direction: row;
}

@media screen and (max-width: 850px) {
    .flex-h {
        min-height: 4vw;
    }
}

.flex-h > div {
    margin: 2vw;
}

.flex-h .konvajs-content {
    margin: auto;
}

.flex-h .sub-title {
    margin-top: 2vw;
    text-align: center;
}
