.types {
    display: flex;
    gap: 4vw;
    background-color: black;
    height: 81.5vh;
    width: 100%;
    margin-top: 4vw;
    margin-left: 0;
    overflow-x: auto;
    align-items: center;
}
.typeLight img {
    width: 8vh;
    cursor: pointer;
}

.typeFurnature,
.typeLight {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.typeFurnature img {
    width: 20.2vh;
    padding-left: 0;
    cursor: pointer;
}

.shrink {
    width: 50% !important;
}

.halfshrink {
    width: 20vw !important;
}
.lhr {
    width: 1px;
    height: 30vw;
    background-color: white;
}

.type img {
    margin-top: 2vw;
    width: 30vh;
    padding-left: 0;
}
.invert {
    filter: invert(1);
}
.typeDouble img {
    margin-top: 2vw;

    width: 60vh;
    padding-left: 0;
}

.onTypeText {
    color: white;
    text-align: center;
    margin-top: 6vw;
    width: 100%;
}
.onTypeTextRight {
    color: white;
    text-align: center;
    margin-top: 4vw;
    width: 100%;
}

.type {
    text-align: center;
    padding-left: 2vw;
    width: 20vw;
}

.type:hover,
.typeDouble:hover {
    color: white;
}

.arrows {
    width: 100%;
    height: 5vw;
    display: flex;
    justify-content: center;
    grid-row-start: 2;
    grid-column-start: 1;
    margin-top: -7vw;
    z-index: 99;
}

.arrowLeft {
    height: 2vw;
    width: 5vw;
    transform: rotate(180deg);
    z-index: 99;
}

.arrowRight {
    height: 2vw;
    width: 5vw;
    z-index: 99;
}
@media screen and (max-width: 850px) {
    .types {
        flex-direction: column;
        width: 100vw;
        border: 1px solid black;
        overflow-x: auto;
        height: -webkit-fill-available;
        padding-bottom: 40vw;
        padding-top: 2vh;
        margin-top: 0;
    }
    .lhr {
        transform: rotate(90deg);
    }
    .type img {
        width: 40vw;
        padding-left: 1vw;
        padding-bottom: 5vh;
        max-height: 40vw;
    }
    .typeDouble img {
        padding-left: 3vw;
        width: 80vw;
    }

    .arrows {
        display: none;
    }

    .type,
    .typeDouble {
        color: white;
        text-align: center;
    }
}
