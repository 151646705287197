.home {
    overflow-y: hidden;
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 15vw 85vw;
}
.mobileNav {
    display: none;
}

.highlight {
    background-color: #f7b200;
    color: black;
}

.left {
    grid-column-start: 1;
    display: grid;
    grid-template-columns: 25% 50% 25%;
    grid-template-rows: 5vh 12vh 21.5vh 34.5vh 15vh;

    height: 100vh;
    background-color: white;
    border-right: 1px black solid;
}

@media (hover: none) {
    .left {
        grid-template-rows: 5% 12% 21.5% 34.5% 15%;
        height: 97%;
    }
}

.logoContainer {
    grid-column-start: 2;
    grid-row-start: 2;
    cursor: pointer;
}

.nav {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 5;
    width: 100%;
    height: 15vh;
}

.navItem {
    display: flex;
    align-items: center;
    padding-left: 20%;
    padding-top: 0;
    margin: 0;

    border-top: 1px white solid;
    font-family: pitch-regular;
    width: 114%;
    height: 5vh;
    cursor: pointer;
    transition: background-color 0.75s, color 0.75s, width 0.75s;
    font-size: var(--font-small);
    border-top: 1px solid black;
}

.navItem:hover,
.navItem[active] {
    background-color: black;
    color: white;
    width: 114%;
    transition: background-color 0.75s, color 0.75s, width 0.75s;
}

.buildSee {
    position: fixed;
    display: flex;
    bottom: 0;
    left: 0;
    right: 0;
    height: 7vh;
    background-color: black;
    color: white;
    font-size: var(--font-mid);
    line-height: var(--font-mid);
    justify-content: center;
    align-items: center;
    cursor: pointer;

    border-top: 1px solid #707070;
    z-index: 30000;
}

.centerText {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-start: 3;
    padding-left: 20%;
    padding-right: 20%;
    width: 100%;
    font-size: var(--font-small);
    padding-top: 8vh;
}

.bentArrow {
    width: 4vw;
}

.bentArrow svg {
    width: 2vw;
    fill: white;
    transform: rotate(90deg) scaleX(-1);
}

.content {
    overflow-y: scroll;
    grid-column-start: 2;
    height: 93vh;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 850px) {
    .mobileNav {
        display: grid;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 11vh;
        grid-template-columns: 50vw 50vw;
        grid-template-rows: 11vh 22vh;

        border-bottom: 1px black solid;
        z-index: 99;
    }
    .mobileLogo {
        width: 7vh;
        padding-left: 4vw;
        padding-top: 2vh;
        padding-bottom: 1vh;
        grid-row-start: 1;
    }
    .mobilePlus {
        position: fixed;
        top: 0;
        right: 0;
        width: 2vh;
        height: 2vh;
        grid-column-start: 2;
        padding-top: 4.5vh;
        padding-right: 4vw;
        z-index: 200000;
    }
    .home {
        grid-template-columns: 0vw 100vw;
    }

    .buildSee {
        position: fixed;
        display: flex;
        z-index: 30000;
    }
    .content {
        margin-top: 11vh;
        height: 83vh;

        overflow-y: auto;
        display: -webkit-box;
        -webkit-overflow-scrolling: touch;
    }

    .left {
        display: none;
    }
    .mobileCopy {
        background-color: white;
        grid-row-start: 2;
        grid-column-start: 1;
        display: flex;
        padding: 4vw;
        border: 1px black solid;
        border-left: none;
        z-index: 99;
        opacity: 1;
        animation-name: fadeInOpacity;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 0.25s;
    }
    .mobileLinks {
        background-color: white;
        grid-row-start: 2;
        grid-column-start: 2;
        z-index: 99;
        overflow-x: hidden;
        border-bottom: 1px solid black;
        opacity: 1;
        animation-name: fadeInOpacity;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 0.25s;
    }
    .mobileLinks .navItem {
        border: 1px black solid;
        border-left: none;
        z-index: 99;
        border-bottom: none;
        height: calc(22vh / 4);
    }
    .open {
        transform: rotate(45deg);
        transition: transform 0.5s;
    }
}
