.conference {
    background-color: black;
    width: 100vw;
    height: 200vh;
}

.logo {
    position: absolute;
    top: 7vw;
    left: 7vw;
    width: 10vw;
    height: 10vw;
    filter: invert();
}

.copy {
    color: white;
    position: absolute;
    top: 20vw;
    left: 7vw;
    width: calc(50vw - 14vw);
    font-weight: bold;
}

.aboutChair {
    position: absolute;
    top: 0vw;
    right: 0vw;
    width: 50vw;
    height: 65vh;
    z-index: 1;
    background-color: white;
    overflow: hidden;
}

.fadeIn {
    opacity: 0;
    animation: fadein 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}
.fadeOut {
    opacity: 0;
    animation: fadeout 1s;
    animation-fill-mode: forwards;
}

.chairTopLeft,
.chairTopRight,
.chair3d {
    background: linear-gradient(45deg, white 0%, rgb(80, 80, 80) 100%);

    position: absolute;
    top: 7vw;
    right: 30vw;
    width: 12vw;
    height: 12vw;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chairTopRight {
    right: 18vw;
}

.chair3d {
    background: none;
    right: 15vw;
    top: 35vh;
    width: 30vw;
    height: 30vw;
}

.info {
    position: absolute;
    top: 15vh;
    right: 3vw;
    width: 11vw;
    height: 65vh;
    background-color: #f4b301;
    z-index: 50;
    padding: 2vw;
    font-size: var(--font-vsmall);
    font-weight: bold;
}

.nugget {
    margin-bottom: 1.5vh;
}

.nuggetName {
    font-size: calc(var(--font-vsmall) * 0.5);
}

.inches {
    display: inline !important;
}
.inches:nth-child(1) span {
    display: inline !important;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
