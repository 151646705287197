.collection .productImage {
    width: 20vw;
}

.h1 {
    margin-left: 4vw;
    margin-bottom: 0;
    width: 50vw;
    border-bottom: 1px black solid;
}

.press .productImage {
    height: 13.3vw;
}

.collectionDetails .productImage {
    max-width: 51.75vw;

    padding-top: 6vw;
}

.collectionDetails {
    background-color: black;
}

.icon img {
    height: 2vw;
    max-width: 4vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
}
.arrows {
    width: 100%;
    height: 5vw;
    display: flex;
    justify-content: center;
    grid-row-start: 2;
    grid-column-start: 1;
}

.arrowLeft {
    height: 2vw;
    width: 5vw;
    transform: scaleX(-1);
}

.arrowRight {
    height: 2vw;
    width: 5vw;
}

.collection {
    display: flex;
    flex-wrap: wrap;
    padding-top: 2vw;
}

.collectionItem {
    width: 24vw;
    padding-left: 4vw;
    padding-top: 4vw;

    padding-bottom: 2.5vw;
    cursor: pointer;
}
.summaryArea {
    display: flex;
    padding-top: 0.5vw;
    width: 100%;
}
.textBlock {
    margin-bottom: 0;
    width: 20vw;
    font-size: var(--font-small);
}

.textBlock .title {
    padding-top: 0;
}
.iconContainer {
    width: 2vw;
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
}
.iconContainer img {
    height: 1.5vw;
    max-width: 1.5vw;
    padding-top: 0.5vw;
}

.title {
    font-size: var(--font-small);
    font-family: "pitch-regular";
    padding-top: 0vw;
}

.designedBy {
    font-size: var(--font-vsmall);
}

.viewButton {
    margin: 0;
    margin-top: 2vw;
    margin-bottom: 0.5vw;
    position: relative;
}

.collectionDetails {
    display: grid;
    grid-template-columns: 75% 25%;
    grid-template-rows: 90% 10%;
    height: 100%;
}
.productImage {
    margin: auto;
    max-height: 70vh;
    object-fit: contain;
}

.collectionInfoPanel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-left: 1px solid #f1ece8;
    padding-left: 1.5vw;
    padding-top: 5vh;
    margin-top: 0;
    background-color: white;
    height: 88vh;
}
.buttonArea {
    padding-top: 0.5vw;
    display: flex;
    align-items: flex-end;
    min-height: 3.5vw;
}

.collectionInfoPanel div {
    padding-bottom: 0.5vw;

    width: 80%;
}

.collectionInfoPanel hr {
    margin-bottom: 1vw;
    margin-right: 10vw;
}

.blurb {
    height: 50vh;
    overflow: scroll;
}
.blurbNOPOS {
    height: 65vh;
    overflow: scroll;
}
@media screen and (max-width: 850px) {
    .collection .productImage {
        width: 92vw;
    }
    .press .productImage {
        height: inherit;
    }

    .collectionItem {
        width: 95vw;
    }
    .textBlock {
        width: 92vw;
    }

    .icon img {
        height: 4vw;
    }
    .iconContainer {
        width: 5vw;
    }
    .iconContainer img {
        height: 3vw;
        max-width: 3vw;
    }
    .collectionDetails {
        display: grid;
        grid-template-columns: 100% 0%;
        grid-template-rows: 50% auto;
        height: 100%;
    }

    .collectionDetails .productImage {
        max-height: 40vh;
        max-width: 92vw;
        width: auto;
        height: auto;
    }

    .arrows {
        margin-top: -12vw;
        height: 20vw;
        z-index: 5;
    }

    .arrowLeft {
        height: 8vw;
        width: 20vw;
    }

    .arrowRight {
        height: 8vw;
        width: 20vw;
    }
    .collectionInfoPanel {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        padding-left: 14vw;
        height: auto;
        padding-bottom: 20vh;
    }
    .blurb {
        height: auto;
        overflow: visible;
    }
    .collection:last-child {
        padding-bottom: 20vh;
    }
}
