.splash {
    overflow-y: hidden;
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 50vw 50vw;
}

.splashRight {
    display: flex;
    flex-direction: row;
    background-color: black;
    height: 100vh;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5.5vw;
    line-height: 5.5vw;
    transition: backgound-color 2s;
    cursor: pointer;
}
.splashRightVanish {
    display: flex;
    flex-direction: row;
    background-color: white;
    height: 100vh;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5.5vw;
    line-height: 5.5vw;
    transition: background-color 2s ease;
}
.splashRight a {
    text-decoration: none;
    color: white;
}

.splashRightText {
    width: 25vw;
    display: flex;
    align-items: flex-end;
}

.flashArrow,
.flashArrowLarge {
    display: inline-block;
    margin: 0;
    padding: 0;
}
.flashArrow svg {
    width: 3vw;
    height: 2.7vw;
    margin: 0;
    padding: 0;
}

.flashArrowLarge svg {
    width: 10vw;
    height: 30vw;
    margin: 0;
    padding: 0;
    transition: width 2s, height 2s;
}

.splashLeft {
    display: flex;
    flex-direction: row;
    height: 100vh;
    color: white;
    display: flex;
    align-items: flex-end;
    justify-content: left;
    background-image: url(../../images/happytosee.jpg);
    background-size: cover;
}

.logoBlock {
    background-color: black;
    width: 20%;
    margin-left: 5%;
    margin-bottom: 5%;
}
.logoBlock img {
    background-color: black;
    width: 80%;
    margin: auto;
    margin-left: 10%;
    padding-top: 10%;
    padding-bottom: 10%;
}

@media screen and (max-width: 850px) {
    .splash {
        grid-template-columns: 100vw;
    }
    .logoBlock img {
        padding-bottom: 0%;
    }
    .splashLeft,
    .splashRight,
    .splashRightVanish {
        height: 50vh;
        font-size: 11vw;
        line-height: 11vw;
    }

    .flashArrow svg {
        width: 6vw;
        height: 5.6vw;
        margin: 0;
        padding: 0;
    }

    .flashArrowLarge svg {
        width: 20vw;
        height: 50vw;
        margin: 0;
        padding: 0;
        transition: width 2s, height 2s;
    }
    .splashRightText {
        width: 50vw;
    }
}
