.cart .logo {
    width: 15vw;
    margin: 5vw;
}

.cart .header {
    width: 100vw;
    content: "";
}

.cart .cartItem {
    display: flex;
    width: 45vw;
    flex-direction: row;
}

.cart .cartItem .details {
    width: 25vw;
}

.cart .cartBody {
    display: flex;
    width: 100vw;
    flex-direction: row;
}

.cart .cartBody .info {
    width: 45vw;
}

.cart .cartBody .info .heading {
    text-align: right;
    font-size: var(--font-large);
    font-weight: bold;
    text-transform: uppercase;
}

.cart .cartBody .info .terms,
.cart .cartBody .info .button {
    display: flex;
    justify-content: flex-end;
}

.cart .empty {
    text-align: center;
}

.cart .total {
    width: 40vw;
    display: flex;
    justify-content: flex-end;
    gap: 5vw;
}
.cart label {
    font-weight: bold;
}

@media screen and (max-width: 850px) {
    .cart .cartItem {
        display: flex;
    }

    .cart .cartBody {
        flex-direction: column;
    }

    .cart .cartBody .info {
        width: 90vw;
    }

    .cart .cartItem .details {
        width: 50vw;
    }
    .cart .cartItem .details div {
        width: 50vw;
    }
    .cart .total {
        margin-left: 6vw;
    }
}
