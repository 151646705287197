.about {
    display: flex;
    flex-direction: row;
}

.aboutHead {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: black;
}

.aboutHead .imageCaption {
    display: relative;
    z-index: 99;
}
.aboutHead .imageCaption img {
    width: 40vw;
    margin-top: 0;
}

img.group2 {
    width: 40vw !important;
    height: auto !important;
    margin-left: 0;
}

.aboutChair {
    display: relative;
    width: 30vw;
    height: 30vw;
    display: inline-block;
    margin-top: 30vw;
    border: 2px solid black;
    overflow: hidden;
    background-color: white;
    z-index: 1;
    margin-bottom: 7vw;
}
.aboutChair img {
    transform: scaleY(1.05);
}
.dim {
    color: black;
}

.row {
    width: 42.5vw;
    display: flex;
    flex-direction: column;
}

.content {
    width: 20vw;
    margin: 6vw;
    padding: 0;
    margin-top: 0vw;
}

.content:nth-child(1) {
    margin-top: 10vw;
}

.imageCaption {
    background-color: black;
    width: 30vw;
    height: 30vw;
    margin: 6vw;
    padding: 0;
    text-align: center;
    color: white;
}

.caption {
    font-size: var(--font-mid);
    padding-top: 2.5vw;
    color: white;
}

.imageCaption img {
    width: 15vw;
    margin-top: 7vw;
}

.bigS {
    display: block;

    width: 30vw;
    height: 30vw;
    margin: 6vw;
    margin-top: -5vw;
    background-color: black;
    color: white;
    font-size: 20vw;
    font-family: pilat-heavy;
    text-align: center;
}

.bareImage {
    width: 30vw;
    margin: 6vw;
    margin-top: 0;
}

.bareImage img {
    width: 30vw;
}

.footer {
    height: 20vw;
    display: flex;
}

.social {
    width: 30vw;
}

.social img {
    width: 5vw;
    padding-left: 6vw;
}

.breakArrow {
    margin-top: 2vw;
    height: 10vw;
}

.signoff {
    font-size: var(--font-large);
    width: 17vw;
}

.types {
    position: relative;
    width: 100%;

    flex-direction: row;
    margin-top: -45vw;
    z-index: 2;
    white-space: nowrap;
    overflow: hidden;
}

.type:nth-child(1) {
    margin-left: -4vw;
}
.type {
    display: inline-block;
    width: 6vw;
    fill: black;
    padding: 1vw;
}
.typeDouble {
    display: inline-block;
    width: 12vw;
    fill: black;
    padding: 1vw;
}
.logoTopGap {
    display: none;
}

@media screen and (max-width: 850px) {
    .logoTopGap {
        display: block;
        width: 40vw;
    }

    .logoTopGap img {
        width: 40vw;
    }

    .about,
    .dim {
        overflow-x: hidden;
    }
    .about {
        overflow-y: visible;
    }

    .row {
        width: 50vw;
        display: flex;
        flex-direction: column;
    }
    .aboutHead .imageCaption img {
        width: 50vw;
        margin-top: 0;
    }

    .aboutHead {
        padding-bottom: 5vh;
    }

    .aboutChair {
        display: relative;
        width: 40vw;
        height: 40vw;
        margin-top: 40vw;
        margin-left: 4vw;
    }
    .aboutChair img {
        transform: scaleY(1.05);
    }
    .bigS {
        width: 40vw;
        height: 40vw;
        font-size: 27vw;
    }

    .imageCaption {
        width: 40vw;
        height: 40vw;
    }
    .content {
        width: 40vw;
    }
    img.group2 {
        width: 50vw !important;
        height: auto !important;
        margin-left: -10vw;
    }
    .bareImage img {
        width: 40vw;
    }
    .signoff {
        width: auto;
        height: auto;
        padding-bottom: 15vh;
    }
    .types {
        position: relative;
        display: none;
    }
    .footer {
        height: auto;
        width: auto;
        display: flex;
    }
}
